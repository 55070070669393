<template>
  <div>
    <ToolbarAdmin />    
  </div>
</template>

<script>
import ToolbarAdmin from '@/components/admin/ToolbarAdmin.vue'

export default {
  components: {
    ToolbarAdmin
  },
  head: {
    title: function () {
      return {
        inner: 'Admin'
      }
    }
  }
}
</script>